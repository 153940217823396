<template>
  <b-row class="justify-content-center">
    <b-col md="8">
      <b-card>
        <b-form v-on:submit.prevent>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Organization" label-for="organization">
                <v-select
                  id="organization"
                  v-model="form.selectedOrganization"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="form.optionsOrganization"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedOrganization"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <!-- Selected: <strong>{{ form.selectedOrganization }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group label="Product" label-for="product">
                <v-select
                  id="product"
                  v-model="form.selectedProduct"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="form.optionsProduct"
                >
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!form.selectedProduct"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                </v-select>
                <!-- Selected: <strong>{{ form.selectedProduct }}</strong> -->
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Price" label-for="price">
                <cleave
                  id="price"
                  placeholder="Price"
                  v-model="form.price"
                  class="form-control"
                  :raw="false"
                  :options="options.number"
                  required
                />
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group label="Selling Price" label-for="selling_price">
                <cleave
                  id="selling_price"
                  placeholder="Selling Price"
                  v-model="form.selling_price"
                  class="form-control"
                  :raw="false"
                  :options="options.number"
                  required
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <!-- submit and reset -->
            <b-col md="6" class="mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="success"
                class="mr-1"
                @click="validation()"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
    <b-col md="4" v-if="form.selectedProduct !== null">
      <b-card>
        <b-form v-on:submit.prevent>
          <b-row>
            <b-col md="12">
              <label for="details" class="text-primary font-weight-bold"
                >Informations</label
              >
            </b-col>
            <b-col md="12">
              <b-img
                id="image"
                :src="form.image"
                fluid
                alt="Product Image"
                v-b-modal.modal-lg
                class="mb-2"
              />
              <b-modal
                id="modal-lg"
                ok-only
                ok-title="close"
                centered
                size="lg"
                title="Large Modal"
                class="text-center"
              >
                <b-img
                  id="image"
                  :src="form.image"
                  width="1000px"
                  fluid
                  alt="product image"
                />
              </b-modal>
            </b-col>
            <b-col md="12">
              <b-row>
                <b-col md="4"> Name </b-col>
                <b-col md="8" class="text-right font-weight-bolder">
                  {{ form.name }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col md="4"> Code </b-col>
                <b-col md="8" class="text-right font-weight-bolder">
                  {{ form.code }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col md="4"> Category </b-col>
                <b-col md="8" class="text-right font-weight-bolder">
                  {{ form.category_name }}
                </b-col>
              </b-row>
              <hr />
              <b-row>
                <b-col md="4"> Unit </b-col>
                <b-col md="8" class="text-right font-weight-bolder">
                  {{ form.unit_name }}
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BImg,
  BFormSelect,
  BListGroupItem,
  BAvatar,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import Prism from "vue-prism-component";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import { $themeConfig } from "@themeConfig";
import Cleave from "vue-cleave-component";

export default {
  components: {
    ToastificationContent,
    vSelect,
    Cleave,
    BImg,
    BFormSelect,
    BListGroupItem,
    BAvatar,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    Prism,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      form: {
        selectedOrganization: null,
        optionsOrganization: [],
        selectedProduct: null,
        optionsProduct: [],
        image: "",
        name: "",
        code: "",
        price: null,
        selling_price: null,
        category_name: "",
        unit_name: "",
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
      },
      errors: "",
      errMessage: "",
    };
  },
  setup() {
    // App Name
    const { baseUrl, apiUrl } = $themeConfig.app;
    return {
      baseUrl,
      apiUrl,
    };
  },
  watch: {
    "form.selectedProduct": function (data) {
      this.trigger(data.value);
    },
  },
  created() {
    this.getOrganization();
    this.getProduct();
  },
  methods: {
    getOrganization() {
      this.$http.get("organizations").then((response) => {
        // console.log(response.data.data)
        this.form.optionsOrganization.push({
          value: null,
          text: "Select 1 Organization",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          // console.log($data)
          this.form.optionsOrganization.push($data);
        }
        // console.log(this.form.optionsOrganization)
      });
    },
    getProduct() {
      this.$http.get("products").then((response) => {
        // console.log(response.data.data)
        this.form.optionsProduct.push({
          value: null,
          text: "Select 1 Product",
        });

        var datas = response.data.data;
        var i;
        for (i = 0; i < datas.length; i++) {
          var $data = {
            value: datas[i].id,
            text: datas[i].name,
          };
          // console.log($data)
          this.form.optionsProduct.push($data);
        }
        // console.log(this.form.optionsProduct)
      });
    },
    trigger($id) {
      this.$http.get("product/" + $id).then((response) => {
        // console.log(response.data.data)
        this.form.name = response.data.data.name;
        const { baseUrl } = $themeConfig.app;
        this.form.image = baseUrl;
        this.form.image += "storage/product/";
        this.form.image += response.data.data.image;
        this.form.price = response.data.data.price;
        this.form.selling_price = response.data.data.selling_price;
        this.form.code = response.data.data.code;
        this.form.category_name = response.data.data.category_name;
        this.form.unit_name = response.data.data.unit_name;
      });
    },
    validation() {
      if (this.form.selectedOrganization === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Organization field is required",
            variant: "warning",
          },
        });
      } else if (this.form.selectedProduct === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Product field is required",
            variant: "warning",
          },
        });
      } else if (this.form.price === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Price field is required",
            variant: "warning",
          },
        });
      } else if (this.form.selling_price === null) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Notification",
            icon: "BellIcon",
            text: "The Selling Price field is required",
            variant: "warning",
          },
        });
      } else {
        this.addCatalog();
      }
    },
    addCatalog() {
      var newPrice = this.form.price;
      newPrice = newPrice.replace(/\,/g, "");
      var newSellingPrice = this.form.selling_price;
      newSellingPrice = newSellingPrice.replace(/\,/g, "");
      this.$http
        .post("sales/catalog/add", {
          organization_id: this.form.selectedOrganization.value,
          product_id: this.form.selectedProduct.value,
          price: newPrice,
          selling_price: newSellingPrice,
        })
        .then((response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Success add Catalog",
              variant: "success",
            },
          });
          location.href = "/sales/catalog";
          // console.log(response.data.data)
        })
        .catch((errors) => {
          this.errMessage = errors.response.data.message;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: this.errMessage,
              variant: "warning",
            },
          });
          console.log(errors.response);
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>